import React from 'react';
import { Helmet } from 'react-helmet';

import ConsultantFeature from '../components/ConsultantFeature';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import KinetikFeature from '../components/KinetikFeature';
import ProjectFeature from '../components/ProjectFeature';
import SectionHeader from '../components/SectionHeader';
import SiteHeader from '../components/SiteHeader/type4';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Index({ props }) {
    return (
        <div className="bg-white">
            <Helmet>
                <title>Convivial</title>
                <link rel="canonical" href="https://convivial.no" />
                <meta name="description" content="Eksperter på bortgjemte data" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>

            <main>
                <SiteHeader />

                <SectionHeader
                    title="Tjenester"
                    header="For kraft- og energibransjen."
                    description="Gjennom vår felles merkevare Kinetik, leverer vi moderne og brukervennlige tjenester til kraft- og energibransjen."
                    backgroundColor="bg-gray-100"
                />

                {/* Kinetik Feature Sections */}
                <KinetikFeature />

                <SectionHeader
                    title="Tjenester"
                    header="Joviale & dyktige konsulenter."
                    description=" Dersom du har en god idé eller trenger å få løst en oppgave, er vi alltid interessert i å slå av en prat for å diskutere&nbsp;muligheter."
                    backgroundColor="bg-white"
                />

                {/* Consultant Feature Sections */}
                <ConsultantFeature />

                <div id="prosjekter" />
                <SectionHeader
                    title="Prosjekter"
                    header="Utvalgte prosjekter vi har jobbet med den siste&nbsp;tiden"
                    description=""
                    backgroundColor="bg-gray-100"
                />

                <ProjectFeature />
                <CTA header="Kan vi hjelpe deg med ditt&nbsp;prosjekt?" subHeader="Ta kontakt!" bgColor="bg-gray-100" contactButtonColor="bg-convivial" />

            </main>
            <Footer />

        </div>
    )
}

export default Index;
